console.clear()
window.scrollTo(0, 0)

import './style.css'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import waterVertexShader from '../src/shaders/water/waterVertexShader.glsl'
import waterFragmentShader from '../src/shaders/water/waterFragmentShader.glsl'
import * as dat from 'dat.gui'
import {gsap} from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger);

/**
 * Declare required Variables
 */

const sectionTwoText = document.querySelector('#sec-two-half')
const sectionThreeText = document.querySelector('#sec-three-half')
const sectionFourText = document.querySelector('#sec-four-half')
const sectionFiveText = document.querySelector('#sec-five-half')

const video = document.querySelector('#video')

/**
 * Base
 */

let sceneReady = false
// Debug
const gui = new dat.GUI()

// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()

/**
 * Loaders
 */

const manager = new THREE.LoadingManager();
manager.onStart = function ( url, itemsLoaded, itemsTotal ) {

	console.log( 'Started loading file: ' + url + '.\nLoaded ' + itemsLoaded + ' of ' + itemsTotal + ' files.' );

};

manager.onLoad = function ( ) {

	console.log( 'Loading complete!');
  sceneReady = true
  scene.background = environmentMap
  scene.environment = environmentMap
  scene.add(lobby)

  updateAllMaterials()
  gsapAnimation()
};


manager.onProgress = function ( url, itemsLoaded, itemsTotal ) {

	console.log( 'Loading file: ' + url + '.\nLoaded ' + itemsLoaded + ' of ' + itemsTotal + ' files.' );

};

manager.onError = function ( url ) {

	console.log( 'There was an error loading ' + url );

};

const textureLoader = new THREE.TextureLoader(manager)
const gltfLoader = new GLTFLoader(manager)
const cubeTextureLoader = new THREE.CubeTextureLoader(manager)

/**
 * Update all materials
 */
const updateAllMaterials = () =>
{
    scene.traverse((child) =>
    {
        if(child instanceof THREE.Mesh && child.material instanceof THREE.MeshStandardMaterial)
        {
            child.material.envMapIntensity = 5
            child.material.needsUpdate = true
            child.castShadow = true
            child.receiveShadow = true
            //child.material.wireframe = true
        }
    })
}


/**
 * Environment map
 */
 const environmentMap = cubeTextureLoader.load([
  '/textures/environmentMaps/0/px.png',
  '/textures/environmentMaps/0/nx.png',
  '/textures/environmentMaps/0/py.png',
  '/textures/environmentMaps/0/ny.png',
  '/textures/environmentMaps/0/pz.png',
  '/textures/environmentMaps/0/nz.png'
])
environmentMap.encoding = THREE.sRGBEncoding



/**
 * Materials
 */
 const waterMaterial = new THREE.ShaderMaterial({
  vertexShader: waterVertexShader,
  fragmentShader: waterFragmentShader,
  //side: THREE.DoubleSide,
  uniforms: {
    uTime: { value: 0},
    uFrequency: { value: new THREE.Vector2(4,3.5)},
    uColorStart: { value: new THREE.Color('#91d1f2')},
    uColorEnd: { value: new THREE.Color('#caeefa')}
  },
  transparent: true
})

const glassMaterial = new THREE.MeshPhysicalMaterial({
  //color: params.color,
  metalness: 0.6,
  roughness: 0.1,
  reflectivity: 0.6, 
  //alphaMap: texture,
  //envMap: environmentMap,
  //envMapIntensity: 0.8,
  transmission: 0.95, // use material.transmission for glass materials
  //specularIntensity: 0.9,
  opacity: 0.7,
  side: THREE.DoubleSide,
  transparent: true
})

const videoTexture = new THREE.VideoTexture(video)
const videoMaterial = new THREE.MeshStandardMaterial({map: videoTexture})
/**
 * Object
 */

let lobby

gltfLoader.load('models/30x30_lobby_all_white.glb',
(gltf) =>
{
    lobby = gltf.scene
    const water = gltf.scene.children.find((child) => child.name === 'pool')
    const window1 = gltf.scene.children.find((child) => child.name === 'glass')
    const window2 = gltf.scene.children.find((child) => child.name === 'glass1')
    const screen = gltf.scene.children.find((child) => child.name === 'screen')
    water.material = waterMaterial
    window1.material = glassMaterial
    window2.material = glassMaterial
    //screen.material = waterMaterial
    
    
    
})


/**
 * GSAP
 */
function gsapAnimation() {

  // ANIMATION FOR TRANSITION FROM SECTION 1 TO SECTION 2
  const timeline1 = gsap.timeline({
    scrollTrigger: {
      trigger: "#section_two", 
      start: "center center",
      end: "center top", 
      scrub: true,
      pin: true,
      pinSpacing: false,
      markers: false,
      //toggleClass: {targets: "#sec-two-half", className: "active"},
      onEnter: self => {
        sectionTwoText.classList.add('active')
      },
      onEnterBack: self => {
        sectionTwoText.classList.add('active')
      },
      onLeave: self => {
        sectionTwoText.classList.remove('active')
      },
      onLeaveBack: self => {
        sectionTwoText.classList.remove('active')
      },
      /* snap: {
        snapTo: [0.9],
        duration: 3
        
      } */
    }
  })

  // ADD 2 ANIMATIONS TO TIMELINE
  timeline1.to(camera.position, {
    x:2.2,
    y:4,
    z:0
  }, 0)

  timeline1.to(camera.rotation, {
    y: - Math.PI / 2
  }, 0) 
  
 

 // ANIMATION FOR TRANSITION FROM SECTION 2 TO SECTION 3
 const timeline2 = gsap.timeline({
  immediateRender: false,
  scrollTrigger: {
    trigger: "#section_three",
    start: "center center",
    end: "center top", 
    /* onStart: self => {
      sectionTwoText.classList.remove('active')
      sectionThreeText.classList.remove('active')
      sectionFourText.classList.remove('active')
      sectionFiveText.classList.remove('active')
    }, */
    scrub: true,
    pin: true,
    pinSpacing: false,
    toggleClass: {targets: "#sec-three-half", className: "active"},
    
    markers: false,
    /*snap: {
      snapTo: [0.9],
       onComplete: self => {
        console.log('snapped')
        sectionThreeText.classList.add('active')
      } 
    } */
    }
  })

  timeline2.to(camera.position, {
    /* x:6,
    y: 2.8,
    z:-10 */
    x:4,
    y: 2.8,
    z:2
  }, 0)

  timeline2.to(camera.rotation, {
    y: - (Math.PI / 4) * 4.3
  }, 0) 
  

 // ANIMATION FOR TRANSITION FROM SECTION 3 TO SECTION 4
 const timeline3 = gsap.timeline({
  immediateRender: false,
  scrollTrigger: {
    trigger: "#section_four", 
    start: "center center",
    end: "center top", 
    scrub: true,
    pin: true,
    pinSpacing: false,
    toggleClass: {targets: "#sec-four-half", className: "active"},
    markers: false,
    //snap: [0.9]
    }
  })

  timeline3.to(camera.position, {
    x:4,
    z:2
  }, 0)

  timeline3.to(camera.rotation, {
    //y: Math.PI / 5
    y: -(Math.PI / 4) * 7.2
  }, 0)

  // ANIMATION FOR TRANSITION FROM SECTION 4 TO SECTION 5
  const timeline4 = gsap.timeline({
    immediateRender: false,
    scrollTrigger: {
      trigger: "#section_five", 
      start: "center center",
      end: "center top", 
      scrub: true,
      pin: true,
      pinSpacing: false,
      toggleClass: {targets: "#sec-five-half", className: "active"},
      markers: false,
      snap: [0.9]
    }
  })

  timeline4.to(camera.position, {
    x:-4,
    z:2
  }, 0)

  timeline4.to(camera.rotation, {
    y: -(Math.PI / 4) * 8.85
  }, 0)
  
}

/**
 * Lights
 */

// SIMULATE SUNLIGHT WITH THIS SPOTLIGHT: ENABLE SHADOWMAPPING AND CHANGE MAP SIZE TO MAKE SHADOWS SHARPER
const sunlight = new THREE.SpotLight(0xffffff,150);
sunlight.position.set(-16, 30, 5);
sunlight.castShadow = true;
sunlight.shadow.bias = -0.0001;
sunlight.shadow.mapSize.width = 1024*3;
sunlight.shadow.mapSize.height = 1024*3;
sunlight.shadow.camera.far = 60;
scene.add( sunlight );

// ADD HEMISPHERE LIGHTING FOR A NICE SOFT OVERALL LIGHT FROM WARM YELLOW (fcf4d4) DOWN TO LIGHT GREY
var hemisphereLight = new THREE.HemisphereLight(0xFFF7D3, 0x807c78, 2.6);
scene.add( hemisphereLight );
gui.add(hemisphereLight, 'intensity').min(0).max(15).step(0.1).name('hemisphereIntensity')


/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(35, sizes.width / sizes.height, 0.1, 100)
camera.position.set(-12, 2.8, 10)
camera.rotation.y = - (Math.PI/6) * 2
scene.add(camera)

// Controls
 //const controls = new OrbitControls(camera, canvas)
//controls.enableDamping = true 

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    antialias: true
})
renderer.shadowMap.enabled = true
renderer.shadowMap.type = THREE.PCFShadowMap
renderer.physicallyCorrectLights = true
renderer.outputEncoding = THREE.sRGBEncoding
renderer.toneMapping = THREE.ACESFilmicToneMapping
renderer.toneMappingExposure = 1.2
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

/**
 * Animate
 */
const clock = new THREE.Clock()

const tick = () =>
{
  const elapsedTime = clock.getElapsedTime()
  waterMaterial.uniforms.uTime.value = elapsedTime


    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()